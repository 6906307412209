<template>
  <div class="profile">
    <div class="container-xl">
      <div class="card card-page">
        <div class="card-header clearfix">
          <div class="card-title float-left">Профиль пользователя</div>
          <div class="float-right">
            <div>
              <span class="text-muted">ID</span>
              {{ profile.paymentCode }}
            </div>
            <div>
              <span class="text-muted">Тип профиля</span>
              {{ userStatusLabel }}
            </div>
          </div>
        </div>
        <div class="card-body">
          <div>{{ profile.name }}</div>
          <dl>
            <dt>{{ profile.email }}</dt>
            <dd>
              <a @click.stop.prevent="changeUsername" href="#">Изменить</a>
            </dd>
          </dl>
          <div v-if="changeUsernameLinkSent" class="text-success">
            Ссылка для подтверждения смены емейла отправлена на Ваш текущий емейл.<br>
            Пожалуйста перейдите по ссылке и следуйте дальнейшим инструкциям.
          </div>
        </div>
        <div class="card-body">
          <dl>
            <dt class="text-muted">Телефон</dt>
            <dd>
              <span v-if="profile.phone">
                +{{ profile.phone.countryCode }}{{ profile.phone.number }}
              </span>
              <i v-if="profile.uuid && !profile.phone" class="text-muted">
                Не указан
              </i>
            </dd>
          </dl>
        </div>
        <div class="card-body">
          <dl>
            <dt class="text-muted">Пароль</dt>
            <dd>
              <a @click.stop.prevent="changePassword" href="#">Изменить</a>
            </dd>
          </dl>
          <div v-if="changePasswordLinkSent" class="text-success">
            Ссылка для подтверждения смены пароля отправлена на Ваш емейл.<br>
            Пожалуйста перейдите по ссылке и следуйте дальнейшим инструкциям.
          </div>
        </div>
        <div class="card-body">
          <dl>
            <dt class="text-muted">Электронная почта для уведомлений</dt>
            <dd>{{ profile.email }}</dd>
          </dl>
        </div>
        <div class="card-body">
          <form @submit.prevent="sendPrimaryMarketAgreement()">
            <dl>
              <dt class="text-muted">Нотариальная доверенность</dt>
              <dd class="d-inline-flex">
                <a v-for="(file, index) in primaryMarketAgreement" v-bind:key="index"
                   :href="file.downloadLink">{{ file.name }}</a>
              </dd>
                <!--                <div class="form-group">-->
                <!--                  <file-upload :files="primaryMarketAgreement" :multiple="false">-->
                <!--                    Нотариальная доверенность-->
                <!--                  </file-upload>-->
                <!--                  <input-errors :errors="errors.notarialScan"/>-->
                <!--                </div>-->
                <!--                <div class="form-group" v-if="primaryMarketAgreement.length !== 0">-->
                <!--                  <button type="submit" class="btn btn-outline-info ml-3">Загрузить</button>-->
                <!--                </div>-->

            </dl>
          </form>
        </div>
        <div class="card-body">
          <dl>
            <dt class="text-muted">Срок действия доверенности</dt>
            <dd>{{ agreementExpiryDate }}</dd>
          </dl>
        </div>
        <div class="card-body">
          <dl>
            <dt class="text-muted">ЭЦП доверенности</dt>
            <dd>
              <a :href="primaryAgreementSign.downloadLink">
                {{ primaryAgreementSign.name }}
              </a>
            </dd>
          </dl>
        </div>
        <div class="card-body">
          <dl>
            <dt class="text-muted">Документы квалифицированного инвестора</dt>
            <dd class="d-flex flex-column">
              <a v-for="(file, index) in qualifiedInvestorsDocs" v-bind:key="index"
                 :href="file.downloadLink">{{ file.name }}</a>
            </dd>
          </dl>
        </div>
        <div v-if="profile.isLender" class="card-body">
          <router-link :to="{ name: 'authorization' }"
                       type="button"
                       class="btn btn-outline-info"
          >В случае смены паспорта
          </router-link>
        </div>
      </div>
    </div>
    <processing :show="processing"/>
  </div>
</template>

<script>
import {Processing, InputErrors, FileUpload} from "@/components/common";
import {createProfileManager} from "@/utils/di";
import userStatusLabels from "@/components/dashboard/components/authorization/data/userStatusLabels";
import moment from "moment/moment";

export default {
  name: "Profile",
  components: {
    Processing,
    InputErrors,
    FileUpload
  },
  inject: ["profile"],
  data() {
    return {
      processing: false,
      changePasswordLinkSent: false,
      changeUsernameLinkSent: false,
      primaryMarketAgreement: {},
      qualifiedInvestorsDocs: {},
      primaryAgreementSign: {},
      errors: {}
    };
  },
  async mounted() {
    if (this.profile.getPrimaryMarketAgreement) {
      this.primaryMarketAgreement = this.profile.getPrimaryMarketAgreement
          .map((link) => {
            const parts = link.split('/');
            let name = parts[parts.length - 1];
            name = name.substr(0, name.lastIndexOf('?token='));
            return {
              name,
              downloadLink: link
            }
          })
    }

    if (this.profile.getQualifiedInvestorsDocuments) {
      this.qualifiedInvestorsDocs = this.profile.getQualifiedInvestorsDocuments.map((link) => {
        const parts = link.split('/');
        let name = parts[parts.length - 1];
        name = name.substr(0, name.lastIndexOf('?token='))
        return {
          name,
          downloadLink: link
        }
      })
    }

    if (this.profile.getPrimaryAgreementSign) {
      const link = this.profile.getPrimaryAgreementSign;
      const parts = link.split('/');
      let name = parts[parts.length - 1];
      name = name.substr(0, name.lastIndexOf('?token='))
      this.primaryAgreementSign = {
        name: name,
        downloadLink: link
      }
    }
  },
  computed: {
    userStatusLabel() {
      return userStatusLabels[this.profile.status] || this.profile.status;
    },
    agreementExpiryDate() {
      if (this.profile.getAgreementExpiryDate) {
        return moment(this.profile.getAgreementExpiryDate).format("DD.MM.YYYY");
      }
    },
  },
  methods: {
    async changePassword() {
      this.processing = true;
      try {
        this.changePasswordLinkSent = false;
        const manager = await createProfileManager();
        await manager.changePasswordRequest();
        this.changePasswordLinkSent = true;
      } finally {
        this.processing = false;
      }
    },
    async changeUsername() {
      this.processing = true;
      try {
        this.changeUsernameLinkSent = false;
        const manager = await createProfileManager();
        await manager.changeUsernameRequest();
        this.changeUsernameLinkSent = true;
      } finally {
        this.processing = false;
      }
    },
    async sendPrimaryMarketAgreement() {
      this.processing = true;
      try {
        const links = this.primaryMarketAgreement.map((item) => item.downloadLink.split('?token')[0])
        const manager = await createProfileManager();
        await manager.setPrimaryMarketAgreement(links);
      } finally {
        this.processing = false;
      }
    }
  }
}
</script>

<style lang="scss">
.profile {
  .card {
    .card-body {
      dl {
        max-width: 50rem;
      }
    }
  }
}
</style>
